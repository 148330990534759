/* eslint-disable no-undef */
import {
  faArrowSquareDown,
  faArrowSquareUp
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import{ Component } from 'react';
import { Link } from 'react-scroll';
import TooltipMF from '../../shared/TooltipMF';
import { StyledPageNav, StyledPageNavArrows } from './PageNav.styled';

class PageNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenPageNav: false,
      activeHero: false,
      sectionPrev: this.props.sections[0]?.section,
      sectionNext: this.props.sections[1]?.section
    };
  }


  updateActiveSection = (e) => {
    let sectionPrev;
    let sectionNext;
    let activeSectionIndex;
    let Allsections = this.props.sections;
    let totalSections = Allsections.length - 1;

    Allsections.map((element, index) => {
      if (element.section === e) {
        activeSectionIndex = index;
      }
    });

    if (activeSectionIndex === 0) {
      sectionPrev = Allsections[0]?.section;
      sectionNext = Allsections[1]?.section;
    } else if (activeSectionIndex < totalSections) {
      sectionPrev = Allsections[activeSectionIndex - 1]?.section;
      sectionNext = Allsections[activeSectionIndex + 1]?.section;
    } else {
      sectionPrev = Allsections[totalSections - 1]?.section;
      sectionNext = Allsections[totalSections]?.section;
    }

    this.setState({
      sectionPrev: sectionPrev,
      sectionNext: sectionNext,
    });
  };

  handleScroll = () => {
    if (!document.querySelector('#hero')) { return }
    const heroBottom = document
      .querySelector('#hero')
      .getBoundingClientRect().bottom;

    const bottomBarTop = document
      .querySelector('#bottom-bar') ? document
        .querySelector('#bottom-bar')
        .getBoundingClientRect().top : false;

    const arrowsTop = document
      .querySelector('#pageNavArrows')
      .getBoundingClientRect().top;


    if (heroBottom > arrowsTop || (bottomBarTop && arrowsTop > bottomBarTop)) {
      this.setState({
        activeHero: true
      });
    } else {
      this.setState({
        activeHero: false
      });
    }
  };

  handleResize = () => {
    const pageNav = document.querySelector('#page-nav');
    const safetyHeight = 120; //extra room for top and bottom bars

    if (!pageNav) {
      return;
    }

    if (pageNav.offsetHeight > window.innerHeight - safetyHeight) {
      this.setState({
        hiddenPageNav: true
      });
    } else {
      this.setState({
        hiddenPageNav: false
      });
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    this.handleScroll();
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    if (this.props.sections?.length <= 1) {
      return null;
    }

    return (
      <StyledPageNav
        className={`${this.state.activeHero ? 'activeHero' : ''} ${this.state.hiddenPageNav ? 'hiddenPageNav' : ''
          }`}
        aria-label={this.props.navLabel}
        id="page-nav"
      >
        <ul>
          {this.props.sections.map((s, i) => (
            <li key={i}>
              <TooltipMF content={s.tooltip} direction="left" distance={18}>
                <Link
                  activeClass="is-active"
                  to={s.section}
                  href={`#${s.section}`}
                  aria-label={s.tooltip}
                  title={s.tooltip}
                  spy={true}
                  id={`pagenav-link-${s.section}`}
                  onSetActive={() => {
                    this.updateActiveSection(s.section);
                  }}
                  offset={i === 0 ? -64 : 0}
                />
              </TooltipMF>
            </li>
          ))}
        </ul>
        <StyledPageNavArrows id="pageNavArrows">
          {this.state.sectionPrev && <Link
            to={this.state.sectionPrev}
            href={`#${this.state.sectionPrev}`}
            aria-label={this.props.prevLabel}
            title={this.props.prevLabel}
            id="pagenav-prev"
          >
            <FontAwesomeIcon icon={faArrowSquareUp} aria-hidden="true" />
          </Link>}

          {this.state.sectionNext && <Link
            to={this.state.sectionNext}
            href={`#${this.state.sectionNext}`}
            aria-label={this.props.nextLabel}
            title={this.props.nextLabel}
            id="pagenav-next"
          >
            <FontAwesomeIcon icon={faArrowSquareDown} aria-hidden="true" />
          </Link>}
        </StyledPageNavArrows>
      </StyledPageNav>
    );
  }
}

export default PageNav;

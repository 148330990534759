import styled from 'styled-components';
import { fontSize, spacing, fontWeight } from '../../../theme';

export const StyledTopbarSocial = styled.div`
`;

export const StyledTopbarSocialTitle = styled.span`
  display: block;
  font-size: ${fontSize('sm')};
  line-height: 1;
  text-transform: uppercase;
  margin-bottom: ${spacing('sm')};
  font-weight: ${fontWeight('medium')};
`;

export const StyledTopbarSocialNav = styled.ul`
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const StyledTopbarSocialNavItem = styled.li`
  font-size: ${fontSize('xl')};
  margin-right: ${spacing('lg')};

  svg {
    display: block;
    width: 1em !important;
  }
`;

import { useUser } from '@auth0/nextjs-auth0/client';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { faBars, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonLink } from 'components/shared/ButtonLink';
import { useI18n } from 'hooks/useI18n';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { useState } from 'react';
import Headroom from 'react-headroom';
import ServiceEventTracking from 'services/service.event-tracking';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import {
  StyledTopbar,
  StyledTopbarContainer,
  StyledTopbarDesktopNav,
  StyledTopbarLogo,
  StyledTopbarMobileBottom,
  StyledTopbarMobileNav,
  StyledTopbarMobileNavContainer,
  StyledTopbarMobileNavHeader,
  StyledTopbarMobileUserActions,
  StyledTopbarNav,
  StyledTopbarNavClose,
  StyledTopbarNavTrigger
} from './Topbar.styled';
import TopbarActions from './TopbarActions';
import TopbarNav from './TopbarNav';
import TopbarSocial from './TopbarSocial';

type TopbarProps = {
  topBarTransparent?: boolean;
  isHeadroomPinned: () => void;
  isHeadroomUnpinned: () => void;
  isTalent?: boolean;
};

const Topbar: React.FC<TopbarProps> = ({
  topBarTransparent,
  isHeadroomPinned,
  isHeadroomUnpinned
}) => {
  const { t } = useI18n('topbar');
  const { locale } = useRouter();

  const [setActive, setActiveState] = useState('');

  function toggleNav(): void {
    setActiveState(setActive === '' ? 'is-active' : '');
  }

  const { user } = useUser();

  return (
    <>
      <Headroom
        downTolerance={5}
        upTolerance={5}
        onPin={isHeadroomPinned}
        onUnpin={isHeadroomUnpinned}
      >
        <StyledTopbar
          className={`${topBarTransparent ? 'is-transparent' : ''}`}
        >
          <StyledTopbarContainer>
            <StyledTopbarNav>
              <StyledTopbarNavTrigger onClick={toggleNav}>
                <FontAwesomeIcon icon={faBars} />
              </StyledTopbarNavTrigger>
              <Link href={getAppUrl(APP_URLS.home, locale)} passHref>
                <StyledTopbarLogo>
                  <img
                    src="https://media-asgard.s3.eu-west-1.amazonaws.com/manfred-media/logo_manfred_color.svg"
                    alt="Manfred logo"
                    className="brand"
                  />
                  <img
                    src="https://media-asgard.s3.eu-west-1.amazonaws.com/manfred-media/logo_manfred_blanco.svg"
                    alt="Manfred logo"
                    className="brand--inverse"
                  />
                </StyledTopbarLogo>
              </Link>

              <StyledTopbarDesktopNav>
                <TopbarNav />
              </StyledTopbarDesktopNav>
            </StyledTopbarNav>
            <TopbarActions />
          </StyledTopbarContainer>
        </StyledTopbar>
      </Headroom>
      <StyledTopbarMobileNav className={`${setActive}`}>
        <StyledTopbarMobileNavContainer>
          <StyledTopbarMobileNavHeader>
            <Link href={getAppUrl(APP_URLS.home, locale)} passHref>
              <StyledTopbarLogo>
                <img
                  src="https://media-asgard.s3.eu-west-1.amazonaws.com/manfred-media/logo_manfred_color.svg"
                  alt="Manfred logo"
                />
              </StyledTopbarLogo>
            </Link>
            <StyledTopbarNavClose onClick={toggleNav}>
              <FontAwesomeIcon icon={faTimes} />
            </StyledTopbarNavClose>
          </StyledTopbarMobileNavHeader>
          <TopbarNav />
          <StyledTopbarMobileBottom>
            <TopbarSocial />
            {!user && (
              <StyledTopbarMobileUserActions>
                <ButtonLink
                  href={`${getAppUrl(APP_URLS.login, locale)}?locale=${locale}`}
                  sizes="sm"
                  isLink
                  aria-label={t('actions.signin')}
                  className="signin"
                >
                  {t('actions.signin')}
                </ButtonLink>

                <ButtonLink
                  href={`${getAppUrl(
                    APP_URLS.join,
                    locale
                  )}?locale=${locale}&source=unete-header`}
                  sizes="sm"
                  variant="secondary"
                  aria-label={t('actions.signup')}
                  className="signup"
                  onClick={(e) =>
                    ServiceEventTracking.trackExternalLandingCtaClicked(
                      e,
                      `${getAppUrl(
                        APP_URLS.join,
                        locale
                      )}?locale=${locale}&source=unete-header`,
                      'unete-header'
                    )
                  }
                >
                  <FontAwesomeIcon icon={faSignInAlt} />
                  <span aria-hidden="true">{t('actions.signup')}</span>
                </ButtonLink>
              </StyledTopbarMobileUserActions>
            )}
          </StyledTopbarMobileBottom>
        </StyledTopbarMobileNavContainer>
      </StyledTopbarMobileNav>
    </>
  );
};

export default Topbar;

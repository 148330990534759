import { useI18n } from 'hooks/useI18n';
import { useState } from 'react';
import { Element } from 'react-scroll';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import Footer from './footer/Footer';
import { StyledPage } from './Page.styled';
import { PageProps } from './Page.types';
import { PageDrawerContextProvider } from './PageDrawerContext';
import PageNav from './pageNav/PageNav';
import ScrollTop from './scrollTop/ScrollTop';
import Topbar from './topbar/Topbar';

const Page: React.FC<PageProps> = ({
  children,
  offerFooter,
  sections,
  className,
  pageVariant,
  filters = <></>,
  hasScrollTop,
  topBarTransparent,
  noTopBar,
  noFooter,
  onDrawerClose,
  customFooter
}) => {
  const { t } = useI18n('common');

  const [isHeadroomPinned, setIsHeadroomPinned] = useState(false);

  function headroomPinned(): void {
    setIsHeadroomPinned(true);
  }

  function headroomUnpinned(): void {
    setIsHeadroomPinned(false);
  }

  return (
    <PageDrawerContextProvider onDrawerClose={onDrawerClose}>
      <StyledPage
        variant={pageVariant}
        className={`${isHeadroomPinned ? 'is-headroom-pinned' : ''}`}
      >
        {!noTopBar && (
          <Topbar
            topBarTransparent={topBarTransparent}
            isHeadroomPinned={headroomPinned}
            isHeadroomUnpinned={headroomUnpinned}
          />
        )}

        <main className={className}>
          <Element id="pageTop" name="pageTop">
            {sections && sections.length > 0 && (
              <PageNav
                sections={sections}
                prevLabel={t('pageNav.prev')}
                nextLabel={t('pageNav.next')}
                navLabel={t('pageNav.label')}
              />
            )}

            {children}
          </Element>
        </main>

        {filters}

        {offerFooter && (
          <Footer offerFooter={offerFooter} hasScrollTop={hasScrollTop} />
        )}

        {customFooter && <>{customFooter}</>}

        {!customFooter && !offerFooter && !noFooter && <Footer />}

        {hasScrollTop && <ScrollTop />}
      </StyledPage>
    </PageDrawerContextProvider>
  );
};

export default Page;

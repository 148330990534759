import styled from 'styled-components';
import { color, container, fontSize, fontWeight, spacing, screen } from 'theme';

export const StyledItemLink = styled.a`
  display: flex;
  align-items: center;
  gap: ${spacing('xs')};
  margin-top: ${spacing('sm')};
  font-size: ${fontSize('xs')};
  line-height: 1.3;
  text-transform: uppercase;
  font-weight: ${fontWeight('extraBold')};
  color: ${color('link')};

  svg {
    width: ${spacing('md')} !important;
    height: ${spacing('md')};
  }
`;

export const StyledGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing('lg')};
  font-size: ${fontSize('sm')};
  line-height: 1.142;

  @media (max-width: ${screen('lgMax')}) {
    gap: ${spacing('sm')};
  }

  > ${StyledItemLink} {
    margin-top: 0;
  }

  > p {
    margin: 0;
    line-height: 1.5;
    font-size: ${fontSize('md')};

    @media (max-width: ${screen('lgMax')}) {
      font-size: ${fontSize('sm')};
      line-height: 1.428;
    }
  }
`;

export const StyledGroupTitle = styled.span`
  font-weight: ${fontWeight('bold')};
  color: ${color('text_subtle')};
  text-transform: uppercase;

  @media (max-width: ${screen('lgMax')}) {
    font-size: ${fontSize('sm')};
  }
`;

export const StyledTitle = styled.span`
  font-weight: ${fontWeight('black')};
  font-size: ${fontSize('xl')};
  line-height: 1.16666;
  color: ${color('navy_700')};

  @media (max-width: ${screen('lgMax')}) {
    font-size: ${fontSize('md')};
  }
`;

export const StyledItem = styled.a`
  display: flex;
  gap: ${spacing('md')};
  color: inherit;

  @media (max-width: ${screen('lgMax')}) {
    gap: ${spacing('sm')};
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${spacing('xs2')};
    font-weight: ${fontWeight('black')};
    color: ${color('link')};
    font-size: ${fontSize('md')};

    @media (max-width: ${screen('lgMax')}) {
      font-size: ${fontSize('sm')};
    }
  }
`;

export const StyledItemIcon = styled.img`
  width: 3.5rem;
  height: 3.5rem;

  @media (max-width: ${screen('lgMax')}) {
    width: ${spacing('xl2')};
    height: ${spacing('xl2')};
  }
`;

export const StyledItemDesc = styled.span`
  color: ${color('text_subtle')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing('xs2')};
  font-weight: ${fontWeight('medium')};
  line-height: 1.428;
  font-size: ${fontSize('sm')};
`;

export const StyledSubnav = styled.div`
  max-width: ${container('xlMax')};
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr) minmax(
      0,
      1fr
    );
  gap: ${spacing('xl2')};
  margin: auto;
  padding-left: ${spacing('lg')};
  padding-right: ${spacing('lg')};

  @media (max-width: ${screen('lgMax')}) {
    grid-template-columns: minmax(0, 1fr);
    padding: 0;
  }
`;

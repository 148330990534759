import {
  faGithub,
  faLinkedin,
  faTelegramPlane,
  faTwitch,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ButtonLink from 'components/shared/ButtonLink.styled';
import { DEFAULT_FILTER } from 'features/job-offers/list/components/JobOffersListComponent.constants';
import { TFunction } from 'hooks/useI18n';
import ServiceEventTracking from 'services/service.event-tracking';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { getUrlParamsFromJobOffersFilter } from 'utils/job-offers-filter';
import { getLocale } from 'utils/localize.utils';
import {
  FooterNavGroupProps,
  FooterNavItemProps,
  FooterNavSocialProps
} from './Footer.types';

export const FOOTER_NAV_GROUPS = (
  t: TFunction,
  locale?: string
): FooterNavGroupProps[] => {
  return [
    {
      title: t('nav.talentTitle'),
      navItems: [
        {
          href: `${getAppUrl(APP_URLS.talent, locale)}`,
          title: t('nav.product')
        },
        {
          href: `${getAppUrl(APP_URLS.manfredDaily, locale)}`,
          title: t('nav.telegramOffers')
        },
        {
          href: `${getAppUrl(
            APP_URLS.jobOffers,
            locale
          )}?${getUrlParamsFromJobOffersFilter(
            DEFAULT_FILTER,
            getLocale(locale)
          )}`,
          title: t('nav.offers')
        },
        {
          href: `${getAppUrl(APP_URLS.salaryCompass, locale)}`,
          title: t('nav.salaryCompass')
        },
        {
          href: `${getAppUrl(APP_URLS.roles, locale)}`,
          title: t('nav.roles')
        }
      ],
      action: (
        <ButtonLink
          href={`${getAppUrl(
            APP_URLS.join,
            locale
          )}?locale=${locale}&source=unete-footer`}
          sizes="xs"
          variant="brandTalent"
          onClick={(e) =>
            ServiceEventTracking.trackExternalLandingCtaClicked(
              e,
              `${getAppUrl(
                APP_URLS.join,
                locale
              )}?locale=${locale}&source=unete-footer`,
              'unete-footer'
            )
          }
        >
          <FontAwesomeIcon icon={faSignInAlt} />
          <span aria-hidden="true">{t('nav.signUp')}</span>
        </ButtonLink>
      )
    },
    {
      title: t('nav.companiesTitle'),
      navItems: [
        {
          href: `${getAppUrl(APP_URLS.companies, locale)}`,
          title: t('nav.services')
        },
        {
          href: `${getAppUrl(APP_URLS.talentEngineLanding, locale)}`,
          title: t('nav.talentEngine')
        },
        {
          href: `${getAppUrl(APP_URLS.hrAsAService, locale)}`,
          title: t('nav.hr-as-a-service')
        },
        {
          href: `${getAppUrl(APP_URLS.manfredDailyCompanies, locale)}`,
          title: t('nav.daily')
        },
        {
          href: `${getAppUrl(APP_URLS.memberGetMember, locale)}`,
          title: t('nav.memberGetMember')
        },
        {
          href: `${getAppUrl(APP_URLS.outOfOffice, locale)}`,
          title: t('nav.outOfOffice')
        },
        {
          href: `${getAppUrl(APP_URLS.theDavidTest, locale)}`,
          title: t('nav.davidTest')
        }
      ]
    },
    {
      title: t('nav.knowledgeTitle'),
      navItems: [
        {
          href: `${getAppUrl(APP_URLS.blog, locale)}`,
          title: t('nav.blog')
        },
        {
          href: `${getAppUrl(APP_URLS.mantechcar24, locale)}`,
          title: t('nav.stateOfTheSector')
        },
        {
          href: `${getAppUrl(APP_URLS.hiringProcessComparison, locale)}`,
          title: t('nav.hiringProcessComparison')
        },
        {
          href: `${getAppUrl(APP_URLS.helpingJuniors, locale)}`,
          title: t('nav.helpingJuniors')
        },
        {
          href: `${getAppUrl(APP_URLS.stateOfHiring, locale)}`,
          title: t('nav.hiringReport')
        }
      ]
    },
    {
      title: t('nav.manfredTitle'),
      navItems: [
        {
          href: `${getAppUrl(APP_URLS.team, locale)}`,
          title: t('nav.us')
        },
        {
          href: `${getAppUrl(APP_URLS.blog, locale)}/compromiso-etico-manfred`,
          title: t('nav.ethicalCommitment')
        },
        {
          href: `${getAppUrl(APP_URLS.battleReport, locale)}`,
          title: t('nav.battleReport')
        },
        {
          href: `${getAppUrl(APP_URLS.joinUs, locale)}`,
          title: t('nav.joinUs')
        }
      ]
    }
  ];
};

export const FOOTER_NAV_SECONDARY = (
  t: TFunction,
  locale?: string
): FooterNavItemProps[] => {
  return [
    {
      href: `${getAppUrl(APP_URLS.termsOfUse, locale)}`,
      title: t('nav.terms')
    },
    {
      href: `${getAppUrl(APP_URLS.privacyPolicy, locale)}`,
      title: t('nav.privacy')
    },
    {
      href: `${getAppUrl(APP_URLS.cookies, locale)}`,
      title: t('nav.cookies')
    }
  ];
};

export const FOOTER_NAV_SOCIAL = (): FooterNavSocialProps[] => {
  return [
    {
      title: 'Twitter',
      href: 'https://twitter.com/getmanfred',
      icon: faTwitter
    },
    {
      title: 'Twitch',
      href: 'https://www.twitch.tv/getmanfred',
      icon: faTwitch
    },
    {
      title: 'LinkedIn',
      href: 'https://es.linkedin.com/company/manfred/',
      icon: faLinkedin
    },
    {
      title: 'GitHub',
      href: 'https://github.com/getmanfred',
      icon: faGithub
    },
    {
      title: 'Telegram',
      href: 'https://t.me/getmanfred',
      icon: faTelegramPlane
    }
  ];
};

import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from 'hooks/useI18n';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { getPublicPath } from 'utils/paths';
import {
  StyledGroup,
  StyledGroupTitle,
  StyledItem,
  StyledItemDesc,
  StyledItemIcon,
  StyledItemLink,
  StyledSubnav,
  StyledTitle
} from './TopbarTalentSubnav.styled';

const TopbarTalentSubnav: React.FC = () => {
  const { t } = useI18n('topbar');
  const { locale } = useRouter();

  const nav = [
    {
      title: t('talent_subnav.profile.title'),
      navItems: [
        {
          href: `${getAppUrl(APP_URLS.talent, locale)}#professional_profile`,
          title: t('talent_subnav.profile.profile.title'),
          desc: t('talent_subnav.profile.profile.desc'),
          icon: getPublicPath('/images/icons/icon_default_softskill.svg')
        },
        {
          href: `${getAppUrl(APP_URLS.blog, locale)}/github-synchronization`,
          title: t('talent_subnav.profile.synch.title'),
          desc: t('talent_subnav.profile.synch.desc'),
          icon: getPublicPath('/images/icons/update.svg')
        }
      ]
    },
    {
      title: t('talent_subnav.search.title'),
      navItems: [
        {
          href: `${getAppUrl(APP_URLS.jobOffers, locale)}`,
          title: t('talent_subnav.search.offers.title'),
          desc: t('talent_subnav.search.offers.desc'),
          icon: getPublicPath('/images/icons/consent.svg')
        },
        {
          href: `${getAppUrl(APP_URLS.manfredDaily, locale)}`,
          title: t('talent_subnav.search.teams.title'),
          desc: t('talent_subnav.search.teams.desc'),
          icon: getPublicPath(
            '/images/icons/roles/software-engineering-left.svg'
          )
        },
        {
          href: `${getAppUrl(APP_URLS.cvApplicationsLanding, locale)}`,
          title: t('talent_subnav.search.tracker.title'),
          desc: t('talent_subnav.search.tracker.desc'),
          icon: getPublicPath('/images/icons/intercultural.svg')
        }
      ]
    },
    {
      title: t('talent_subnav.growth.title'),
      navItems: [
        {
          href: `${getAppUrl(APP_URLS.salaryCompass, locale)}`,
          title: t('talent_subnav.growth.benchmark.title'),
          desc: t('talent_subnav.growth.benchmark.desc'),
          icon: getPublicPath('/images/icons/usage-data.svg')
        }
      ]
    }
  ];

  return (
    <StyledSubnav>
      <StyledGroup>
        <StyledTitle>{t('talent_subnav.ally.title')}</StyledTitle>
        <p>{t('talent_subnav.ally.desc')}</p>
        <Link href={`${getAppUrl(APP_URLS.talent, locale)}`} passHref>
          <StyledItemLink>
            <FontAwesomeIcon icon={faCheckCircle} />
            {t('talent_subnav.ally.link')}
          </StyledItemLink>
        </Link>
      </StyledGroup>
      {nav.map((group, index) => (
        <StyledGroup key={index}>
          <StyledGroupTitle>{group.title}</StyledGroupTitle>
          {group.navItems.map((navItem, index) => (
            <Link key={index} href={navItem.href} passHref>
              <StyledItem>
                <StyledItemIcon src={navItem.icon} alt="" />
                <div>
                  <span>{navItem.title}</span>
                  <StyledItemDesc>{navItem.desc}</StyledItemDesc>
                </div>
              </StyledItem>
            </Link>
          ))}
        </StyledGroup>
      ))}
    </StyledSubnav>
  );
};

export default TopbarTalentSubnav;

import {
  faLinkedin,
  faTelegramPlane,
  faTwitter
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from 'hooks/useI18n';
import {
  StyledTopbarSocial,
  StyledTopbarSocialNav,
  StyledTopbarSocialNavItem,
  StyledTopbarSocialTitle
} from './TopbarSocial.styled';

const TopbarSocial = () => {
  const { t } = useI18n('topbar');
  return (
    <StyledTopbarSocial>
      <StyledTopbarSocialTitle>
        {t('nav.socialMediaTitle')}
      </StyledTopbarSocialTitle>
      <StyledTopbarSocialNav>
        <StyledTopbarSocialNavItem>
          <a
            href="https://t.me/getmanfred"
            title="Telegram"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FontAwesomeIcon aria-hidden="true" icon={faTelegramPlane} />
          </a>
        </StyledTopbarSocialNavItem>
        <StyledTopbarSocialNavItem>
          <a
            href="https://es.linkedin.com/company/manfred"
            title="Linkedin"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FontAwesomeIcon aria-hidden="true" icon={faLinkedin} />
          </a>
        </StyledTopbarSocialNavItem>
        <StyledTopbarSocialNavItem>
          <a
            href="https://twitter.com/getmanfred"
            title="Twitter"
            target="_blank"
            rel="noreferrer noopener"
          >
            <FontAwesomeIcon aria-hidden="true" icon={faTwitter} />
          </a>
        </StyledTopbarSocialNavItem>
      </StyledTopbarSocialNav>
    </StyledTopbarSocial>
  );
};

export default TopbarSocial;

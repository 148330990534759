import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from 'hooks/useI18n';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { getPublicPath } from 'utils/paths';
import {
  StyledGroup,
  StyledGroupTitle,
  StyledItem,
  StyledItemDesc,
  StyledItemIcon,
  StyledItemLink,
  StyledSubnav,
  StyledTitle
} from './TopbarTalentSubnav.styled';

type navItemTypes = {
  href: string;
  title: string;
  desc?: string;
  link?: string;
  icon?: string;
  external?: boolean;
};

type navItemsTypes = {
  title: string;
  desc?: string;
  link?: string;
  href?: string;
  navItems?: navItemTypes[];
};

const TopbarCompaniesSubnav: React.FC = () => {
  const { t } = useI18n('topbar');
  const { locale } = useRouter();

  const nav: navItemsTypes[] = [
    {
      title: t('companies_subnav.products.title'),
      navItems: [
        {
          href: getAppUrl(APP_URLS.talentEngineLanding, locale),
          title: t('companies_subnav.products.manfred.title'),
          desc: t('companies_subnav.products.manfred.desc'),
          icon: getPublicPath('/images/companies/nav/manfred.svg')
        },
        {
          href: 'https://circular.io/',
          external: true,
          title: t('companies_subnav.products.circular.title'),
          desc: t('companies_subnav.products.circular.desc'),
          icon: getPublicPath('/images/companies/nav/circular.svg')
        },
        {
          href: getAppUrl(APP_URLS.manfredDailyCompanies, locale),
          title: t('companies_subnav.products.daily.title'),
          desc: t('companies_subnav.products.daily.desc'),
          icon: getPublicPath('/images/companies/nav/daily.svg')
        }
      ]
    },
    {
      title: t('companies_subnav.consultancy.title'),
      desc: t('companies_subnav.consultancy.desc'),
      link: t('companies_subnav.consultancy.link'),
      href: getAppUrl(APP_URLS.hrAsAService, locale)
    },
    {
      title: t('companies_subnav.resources.title'),
      navItems: [
        {
          href: `${getAppUrl(APP_URLS.outOfOffice, locale)}`,
          title: t('companies_subnav.resources.out-of-office.title'),
          desc: t('companies_subnav.resources.out-of-office.desc'),
          icon: getPublicPath('/images/icons/writing.svg'),
          link: t('companies_subnav.resources.out-of-office.link')
        },
        {
          href: `${getAppUrl(APP_URLS.mantechcar24, locale)}`,
          title: t('companies_subnav.resources.career-report.title'),
          desc: t('companies_subnav.resources.career-report.desc'),
          icon: getPublicPath('/images/icons/usage-data.svg')
        },
        {
          href: `${getAppUrl(APP_URLS.talentEngineBlog, locale)}`,
          title: t('companies_subnav.resources.blog.title'),
          desc: t('companies_subnav.resources.blog.desc'),
          icon: getPublicPath('/images/icons/chat.svg')
        }
      ]
    }
  ];

  return (
    <StyledSubnav className="companies">
      <StyledGroup>
        <StyledTitle>{t('companies_subnav.growth.title')}</StyledTitle>
        <p>{t('companies_subnav.growth.desc')}</p>
        <Link href={`${getAppUrl(APP_URLS.companies, locale)}`} passHref>
          <StyledItemLink>
            <FontAwesomeIcon icon={faCheckCircle} />
            {t('companies_subnav.growth.link')}
          </StyledItemLink>
        </Link>
      </StyledGroup>
      {nav.map((group, index) => (
        <StyledGroup key={index}>
          <StyledGroupTitle>{group.title}</StyledGroupTitle>
          {group.desc && <StyledItemDesc>{group.desc}</StyledItemDesc>}
          {group.link && group.href && (
            <Link href={group.href} passHref>
              <StyledItemLink>
                <FontAwesomeIcon icon={faCheckCircle} />
                {group.link}
              </StyledItemLink>
            </Link>
          )}
          {group.navItems &&
            group.navItems.map((navItem, index) => (
              <Link key={index} href={navItem.href} passHref>
                <StyledItem target={navItem.external ? '_blank' : ''}>
                  <StyledItemIcon src={navItem.icon} alt="" />
                  <div>
                    <span>{navItem.title}</span>
                    <StyledItemDesc>{navItem.desc}</StyledItemDesc>
                    {navItem.link && (
                      <StyledItemLink as="span">
                        <FontAwesomeIcon icon={faCheckCircle} />
                        {navItem.link}
                      </StyledItemLink>
                    )}
                  </div>
                </StyledItem>
              </Link>
            ))}
        </StyledGroup>
      ))}
    </StyledSubnav>
  );
};

export default TopbarCompaniesSubnav;

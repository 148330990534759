import { ManfrediteUpdateRequest } from '@asgard/api-common';
import { Button } from 'components/shared/Button';
import Dropdown from 'components/shared/Dropdown';
import { LoggedUserContext } from 'context/logged-user/loggeduser.context';
import { SlugsContext } from 'context/slugs/slugs.context';
import { useManfredite, useManfrediteUpdate } from 'hooks/api/manfredite';
import { useRouter } from 'next/dist/client/router';
import { useContext, useEffect, useRef, useState } from 'react';
import { getTranslatedText } from 'services/multilingual.service';
import ServiceEventTracking from 'services/service.event-tracking';
import CookiesService, {
  COOKIES_NEXT_LOCALE
} from 'services/ui/cookies.service';
import { APP_URLS, getAppUrl } from 'utils/app-urls';
import { StyledLanguage } from './TopbarLanguage.styled';

const TopbarLanguage = (): JSX.Element => {
  const { locale, asPath } = useRouter();
  const { slug, hasSlugForLang } = useContext(SlugsContext);
  const { user, refreshUserData } = useContext(LoggedUserContext);
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  const { manfredite } = useManfredite();
  const { updateManfrediteFromModel } = useManfrediteUpdate();

  const handleCloseLanugageMenuClick = (e: MouseEvent): void => {
    if (node.current?.contains(e.target as Node)) {
      return;
    }
    setLanguageMenuOpen(false);
  };

  const handleLangChange = async (newLang: string): Promise<void> => {
    CookiesService.add(COOKIES_NEXT_LOCALE, newLang);

    // Event tracking with user trait
    ServiceEventTracking.track(
      {
        type: 'Settings - Interface Language Changed',
        properties: {
          title: document?.title,
          fromPreferredLanguage: locale?.toLowerCase(),
          toPreferredLanguage: newLang?.toLowerCase()
        }
      },
      {
        preferredLanguage: newLang?.toLowerCase()
      }
    );

    if (user && manfredite) {
      try {
        await updateManfrediteFromModel(manfredite, {
          preferredLanguage:
            newLang.toUpperCase() as ManfrediteUpdateRequest['preferredLanguage']
        });
      } catch (ex) {
        console.warn(
          `Could not update Manfredite preferred language. Exception: ${ex}`
        );
      }

      refreshUserData({
        preferredLanguage: newLang
      });

      // If user is logged in, identify user in tracking system to update the preferredLanguage trait
      ServiceEventTracking.identifyUser({
        ...user,
        preferredLanguage: newLang?.toLowerCase()
      });
    }

    let targetPath = `/${newLang}${asPath}`;

    if (asPath.startsWith('/blog/page')) {
      targetPath = `/${newLang}/blog`;

      window.location.href = `${window.location.origin}${targetPath}`;
      return;
    }

    if (slug && asPath.startsWith('/blog/category')) {
      if (!hasSlugForLang(newLang)) {
        targetPath = `/${newLang}/blog`;

        window.location.href = `${window.location.origin}${targetPath}`;
        return;
      }

      targetPath = `/${newLang}/blog/category/${getTranslatedText(
        slug,
        newLang
      )}`;

      window.location.href = `${window.location.origin}${targetPath}`;
      return;
    }

    if (slug && asPath.startsWith('/blog') && hasSlugForLang(newLang)) {
      targetPath = `/${newLang}/blog/${getTranslatedText(slug, newLang)}`;

      window.location.href = `${window.location.origin}${targetPath}`;
      return;
    }

    window.location.href = `${window.location.origin}${targetPath}`;
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleCloseLanugageMenuClick);

    return () => {
      document.removeEventListener('mousedown', handleCloseLanugageMenuClick);
    };
  }, []);

  const languages = [
    {
      label: 'English',
      value: 'en',
      href: getAppUrl(APP_URLS.home, 'en'),
      activeClassCondition: locale === 'en'
    },
    {
      label: 'Español',
      value: 'es',
      href: getAppUrl(APP_URLS.blog, 'es'),
      activeClassCondition: locale === 'es'
    }
  ];
  return (
    <StyledLanguage ref={node}>
      <Button
        variant="tertiary"
        sizes="sm"
        isTrigger
        onClick={() => setLanguageMenuOpen(!languageMenuOpen)}
        aria-haspopup="listbox"
        aria-controls="language-dropdown"
        aria-label="Selected Language: EN"
        aria-expanded={languageMenuOpen ? 'true' : 'false'}
        className="language-trigger"
      >
        {locale?.toUpperCase()}
      </Button>
      <Dropdown
        id="language-dropdown"
        options={languages}
        style={{ display: languageMenuOpen ? 'block' : 'none' }}
        onOptionSelect={handleLangChange}
      />
    </StyledLanguage>
  );
};

export default TopbarLanguage;

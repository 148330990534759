import styled from 'styled-components';
import { color, radius, screen, spacing, zindex } from '../../../theme';

export const StyledPageNav = styled.nav`
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: ${zindex('pageNav')};
  padding-right: ${spacing('lg')};

  &.hiddenPageNav {
    opacity: 0;
    pointer-events: none;
  }

  @media (max-width: ${screen('lgMax')}) {
    display: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;

    li {
      padding: ${spacing('xs')};
    }

    a {
      display: block;
      width: ${spacing('xs')};
      height: ${spacing('xs')};
      border-radius: ${radius('full')};
      background-color: ${color('midnight_300')};
      border: ${spacing('xs')} solid transparent;
      background-clip: padding-box;
      box-sizing: content-box;
      transition: 0.3s;

      &:hover {
        border-color: ${color('midnight_200')};
      }

      &.is-active {
        background-color: ${color('active')};
        border-color: ${color('midnight_700')};

        &:hover {
          border-color: ${color('selected')};
        }
      }
    }
  }
`;

export const StyledPageNavArrows = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${spacing('xl3')};

  a {
    width: ${spacing('xl')};
    height: ${spacing('xl')};
    color: ${color('midnight_800')} !important;
    margin-top: ${spacing('xs2')};
    transition: 0.3s;

    &:hover {
      color: ${color('midnight_400')} !important;
    }

    .activeHero & {
      color: ${color('midnight_200')} !important;

      &:hover {
        color: ${color('midnight_600')} !important;
      }
    }

    svg {
      width: ${spacing('xl')} !important;
      height: ${spacing('xl')};
    }
  }
`;

import Button from 'components/shared/Button.styled';
import { StyledDropdown } from 'components/shared/Dropdown.styled';
import styled from 'styled-components';
import { screen, spacing } from '../../../theme';

export const StyledLanguage = styled.div`
  position: relative;

  ${Button} {
    margin-left: ${spacing('xs')};
    min-height: 2.75rem;

    @media (max-width: ${screen('mdMax')}) {
      margin-left: ${spacing('xs2')};
    }
  }

  ${StyledDropdown} {
    top: calc(100% + 14px);
  }
`;

import { faArrowUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useI18n } from 'hooks/useI18n';
import { useEffect, useState } from 'react';
import {
  StyledScrollTop,
  StyledScrollTopIcon,
  StyledScrollTopText
} from './ScrollTop.styled';

const ScrollTop = () => {
  const { t } = useI18n('common');
  const [displayScrollTop, setDisplayScrollTop] = useState(false);

  const handleScroll = (): void => {
    if (window.innerHeight * 2 > window.scrollY) {
      setDisplayScrollTop(false);
    } else {
      setDisplayScrollTop(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  });

  return (
    <StyledScrollTop
      href="#pageTop"
      to="pageTop"
      offset={-128}
      className={`${displayScrollTop ? 'is-visible' : ''}`}
    >
      <StyledScrollTopIcon>
        <FontAwesomeIcon icon={faArrowUp} aria-hidden="true" />
      </StyledScrollTopIcon>

      <StyledScrollTopText>{t('pageNav.goToTop')}</StyledScrollTopText>
    </StyledScrollTop>
  );
};

export default ScrollTop;

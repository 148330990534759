import { StyledDropdown } from 'components/shared/Dropdown.styled';
import styled from 'styled-components';
import { color, container, fontSize, fontWeight, screen, spacing } from 'theme';
import { StyledLanguage } from '../topbar/TopbarLanguage.styled';

export const StyledFooter = styled.footer`
  background-color: ${color('midnight_900')};
  padding-top: ${spacing('xl4')};
  padding-bottom: ${spacing('xl4')};
  color: ${color('text_default_inverse')};

  &.not-scrollTop {
    padding-bottom: ${spacing('xl3')};
  }
`;

export const StyledFooterContainer = styled.div`
  max-width: ${container('xlMax')};
  padding-left: ${spacing('md')};
  padding-right: ${spacing('md')};
  margin: auto;
  display: flex;
  flex-direction: column;
`;

export const StyledFooterMain = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing('xl')};

  @media (min-width: ${screen('lg')}) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const StyledFooterLogoLang = styled.div`
  flex-shrink: 0;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing('sm')};

  img {
    display: block;
    width: auto;
    height: ${spacing('xl2')};
  }

  ${StyledLanguage} button {
    margin: 0;
    padding: 0;
    color: ${color('text_default_inverse')};
    min-height: auto;

    &:hover {
      color: inherit;
      background-color: transparent;
    }

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.538289 0.730957H7.46171C7.94067 0.730957 8.18015 1.30948 7.84111 1.64852L4.38075 5.11158C4.17087 5.32146 3.82913 5.32146 3.61925 5.11158L0.158886 1.64852C-0.180154 1.30948 0.0593268 0.730957 0.538289 0.730957Z' fill='%23DEDCE4'/%3E%3C/svg%3E%0A");
    }
  }

  ${StyledDropdown} {
    left: 0;
    right: unset;
    color: ${color('text_default')};
  }
`;

export const StyledFooterCopyright = styled.small`
  font-size: ${fontSize('xs')};
  font-weight: ${fontWeight('medium')};
  color: ${color('text_subtle_inverse')};
`;

export const StyledFooterNav = styled.nav`
  display: flex;
  flex-direction: column;
  gap: ${spacing('xl2')};

  @media (min-width: ${screen('md')}) {
    flex-direction: row;
  }

  @media (min-width: ${screen('lg')}) {
    justify-content: flex-end;
    margin-left: auto;
  }
`;

export const StyledNavGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${spacing('md')};

  @media (min-width: ${screen('md')}) {
    max-width: 11rem;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: ${spacing('sm')};
  }
`;

export const StyledNavGroupTitle = styled.div`
  text-transform: uppercase;
  font-weight: ${fontWeight('black')};
  font-size: ${fontSize('sm')};
`;

export const StyledFooterNavItem = styled.a`
  font-weight: ${fontWeight('medium')};
  font-size: ${fontSize('sm')};
  line-height: 1;
  color: ${color('link_inverse')};

  &:hover {
    color: ${color('link_inverse_hover')};
  }

  &:active,
  &.active {
    color: ${color('link_inverse_active')};
  }
`;

export const StyledFooterNavCopy = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing('sm')};
  margin-top: ${spacing('xl2')};
  padding-top: ${spacing('xl2')};
  border-top: 1px solid ${color('midnight_800')};

  @media (min-width: ${screen('md')}) {
    margin-top: ${spacing('xl4')};
    padding-top: ${spacing('xl4')};
  }
`;

export const StyledFooterSecondary = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: ${spacing('md')};

  @media (min-width: ${screen('md')}) {
    flex-direction: row;
    justify-content: space-between;
  }

  ul {
    display: flex;
    gap: ${spacing('md')};
  }

  ${StyledFooterNavItem} {
    font-size: ${fontSize('xs')};
  }

  a {
    color: ${color('text_white')};

    &:hover {
      color: ${color('link_inverse_hover')};
    }

    &:active,
    &.active {
      color: ${color('link_inverse_active')};
    }

    svg {
      width: ${spacing('mdlg')} !important;
      height: ${spacing('mdlg')};
    }
  }
`;

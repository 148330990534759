import { Link } from 'react-scroll';
import styled from 'styled-components';
import {
  color,
  fontSize,
  fontWeight,
  radius,
  screen,
  shadow,
  spacing,
  zindex
} from '../../../theme';

export const StyledScrollTop = styled(Link)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: ${zindex('scrollTop')};
  background-color: ${color('emerald_500')};
  color: ${color('text_white')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: ${radius('full')};
  transition: 0.3s;
  width: ${spacing('xl3')};
  height: ${spacing('xl3')};
  box-shadow: ${shadow('lg')};

  &:not(.is-visible) {
    opacity: 0;
    pointer-events: none;
  }

  &:hover {
    color: ${color('text_white')};
    background-color: ${color('indigo_500')};
  }

  &:focus-visible {
    color: ${color('text_white')};
    background-color: ${color('indigo_500')};
  }

  &:active,
  &:focus {
    color: ${color('text_white')};
  }

  @media (min-width: ${screen('md')}) {
    bottom: 0;
    right: 50%;
    transform: translate(50%, 64px);
    width: 128px;
    height: auto;
    border-radius: ${radius('full')} ${radius('full')} 0 0;
    padding: ${spacing('xl')};
    box-shadow: unset;

    &:hover {
      transform: translate(50%, 0);
    }
  }
`;

export const StyledScrollTopIcon = styled.div`
  svg {
    width: ${spacing('lg')} !important;
    height: ${spacing('lg')} !important;
  }

  @media (min-width: ${screen('md')}) {
    margin-bottom: ${spacing('xs')};
  }
`;

export const StyledScrollTopText = styled.span`
  text-transform: uppercase;
  font-size: ${fontSize('xs')};
  font-weight: ${fontWeight('black')};

  @media (max-width: ${screen('mdMax')}) {
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    white-space: nowrap;
  }
`;

import styled, { css } from 'styled-components';
import {
  color,
  fontSize,
  fontWeight,
  screen,
  shadow,
  spacing
} from '../../../theme';

export const StyledTopbarNavItem = styled.a`
  text-transform: uppercase;
  font-weight: ${fontWeight('medium')};
  line-height: 1;
  vertical-align: text-bottom;
  color: ${color('text_subtle')};

  @media (max-width: ${screen('lgMax')}) {
    display: inline-flex;
    align-items: center;

    &::before {
      content: '';
      width: ${spacing('md')};
      height: 1px;
      background-color: ${color('text_subtle')};
      margin-right: ${spacing('xs')};
    }
  }

  &.active {
    color: ${color('current')};

    &::before {
      background-color: ${color('current')};
      height: 2px;
    }
  }

  .mobileSubnavTrigger & {
    background-color: unset;
    border: 0;
    padding: 0;
    width: 100%;
    font-size: inherit;
  }
`;

export const StyledTopbarNavItemIcon = styled.div`
  margin-left: auto;
  color: ${color('link')};

  svg {
    width: ${spacing('md')};
    height: ${spacing('md')};
  }
`;

export const StyledSubnav = styled.div`
  display: none;
  position: absolute;
  top: ${spacing('xl4')};
  left: 0;
  right: 0;
  padding: ${spacing('xl')};
  background-color: ${color('white')};
  border-top: 1px solid ${color('midnight_200')};
  box-shadow: ${shadow('md')};

  &:hover {
    display: block;
  }

  @media (max-width: ${screen('lgMax')}) {
    display: block !important;
    margin-top: ${spacing('xs')};
    padding: ${spacing('md')};
    bottom: 0;
    width: 16.25rem;
    transform: translateX(100%);
    transition: transform 0.3s;
    overflow: auto;
    border-top: 0;
    box-shadow: unset;

    &.is-active {
      transform: translateX(0);
    }
  }
`;

export const StyledSubnavClose = styled.button`
  background-color: unset;
  padding: 0;
  border: none;
  display: flex;
  gap: ${spacing('xs')};
  color: ${color('link')};
  text-transform: uppercase;
  font-size: ${fontSize('xs')};
  font-weight: ${fontWeight('black')};

  svg {
    width: ${spacing('md')};
    height: ${spacing('md')};
  }
`;

export const StyledSubnavTitle = styled.div`
  border-bottom: 1px solid ${color('midnight_200')};
  color: ${color('text_subtle')};
  text-transform: uppercase;
  font-size: ${fontSize('md')};
  font-weight: ${fontWeight('black')};
  padding-top: ${spacing('md')};
  padding-bottom: ${spacing('md')};
  margin-bottom: ${spacing('md')};
`;

export const StyledTopbarNav = styled.nav<{
  isTalent?: boolean;
  isCompanies?: boolean;
}>`
  height: 100%;
  display: flex;
  ul {
    display: flex;
    align-items: center;

    @media (max-width: ${screen('lgMax')}) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  li {
    padding-left: ${spacing('sm')};
    padding-right: ${spacing('sm')};
    height: ${spacing('xl4')};
    display: flex;
    align-items: center;
    margin-top: -${spacing('xs')};
    margin-bottom: -${spacing('xs')};

    @media (max-width: ${screen('lgMax')}) {
      padding: 0;
      margin-bottom: ${spacing('md')};
      height: unset;
      display: initial;
      width: 100%;
    }

    &:hover > ${StyledSubnav} {
      display: block;

      @media (max-width: ${screen('lgMax')}) {
        display: none;
      }
    }

    &.mobileSubnavTrigger {
      @media (min-width: ${screen('lg')}) {
        display: none;
      }
    }

    &.desktopSubnavTrigger {
      @media (max-width: ${screen('lgMax')}) {
        display: none;
      }
    }
  }

  ${({ isTalent }) =>
    isTalent &&
    css`
      ${StyledTopbarNavItem} {
        &:hover {
          color: ${color('active')} !important;
        }
      }
    `}

  ${({ isCompanies }) =>
    isCompanies &&
    css`
      ${StyledTopbarNavItem} {
        &:hover {
          color: ${color('active')} !important;
        }
      }
    `}
`;
